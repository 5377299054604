<template>
  <div>
    <h1>Bienvenido a su apartado para capturar sus departamentos</h1>
    <b-form @reset="onReset" >
      <b-form-group id="input-group-1" label="ID departamento:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="departamentoActual.identificador"
          placeholder="Ingrese el ID del departamento"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Clave departamento general:" label-for="input-2">
        <b-form-input
          v-model="departamentoActual.general"
          placeholder="Ingrese la clave del departamento general">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Clave departamento auxiliarr:" label-for="input-3">
        <b-form-input
          v-model="departamentoActual.auxiliar"
          placeholder="Ingrese la clave del departamento auxiliar">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Nombre responsable:" label-for="input-4">
        <b-form-input
          v-model="departamentoActual.n_responsable"
          placeholder="Ingrese el nombre del responsable">
        </b-form-input>
      </b-form-group>

   
    <b-button variant="dark" @click="enviarDepartamento" @dblclick="reloadPage">
      Enviar centro de carga
    </b-button>

    <b-button variant="dark" @click="reloadPage">
      Dar de alta otro centro de carga
    </b-button>

    <div>
    <br>
      <b-table striped hover :items="items"></b-table>
    </div>

    <br>
    <h6>Resumen centro</h6>
    {{departamentoActual}}
    <br>
    <h6>Resumen Inventario Centros</h6>
    {{inventario_departamentos}}

    <br>



    </b-form>

  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        inventario_departamentos: {
          departamentos:[
            {}
          ]
        },

        departamentoActual:{
              identificador:'',
              general:'',
              auxiliar:'',
              n_responsable:'',
            },
      
        items: [
          { ID: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez'},

        ],
        
      }
    },
    methods: {
    
      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta

        this.departamentoActual.identificador=''
        this.departamentoActual.ubicacion=''
        this.departamentoActual.centro=''
      },

      reloadPage() {
      window.location.reload();
    },

      enviarDepartamento(){
        this.inventario_departamentos.departamentos.push(JSON.parse(JSON.stringify(this.departamentoActual)));
          alert('Se guardaron correctamente los datos del departamento');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

    }
  }
</script>